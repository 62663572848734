@layer base {
    html {
        @apply scroll-smooth;
    }

    body {
        @apply font-regular font-pp overflow-x-hidden text-dark bg-white text-24;
    }
}

@layer utilities {
    [x-cloak] {
        display: none !important;
    }
}
