.text-size-h1-home {
    font-size: min(40vw, 300px);
    margin-top: max(-60px, -7vw);
    margin-left: -2.5vw;

    @media (min-width: theme("screens.lg")) {
        margin-left: -20px;
    }
    line-height: 1;
}

.text-size-loading-animation {
    font-size: min(15vw, 300px);
    margin-top: -2vw;

    margin-left: -1vw;

    @media (min-width: theme("screens.lg")) {
        margin-left: -20px;
    }
}
