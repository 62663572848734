.loading-animation {
    --progress: 0%;

    clip-path: polygon(
        0 var(--progress),
        100% var(--progress),
        100% 100%,
        0 100%
    );

    .loading-animation-cursor-item {
        @apply absolute bg-white;

        animation: fade-out 500ms ease-out forwards; // Must match the timeout duration in js

        @keyframes fade-out {
            from {
                opacity: 1;
                transform: translateY(0);
            }

            to {
                opacity: 0;
                transform: translateY(30px);
            }
        }
    }
}
