.animation-fade-in {
    animation: fade-in 300ms ease-out forwards;

    @keyframes fade-in {
        from {
            opacity: 0;
            transform: translateY(30px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
