.border-bottom{
    --scale: 0;

    &::after{
        content: "";

        @apply absolute left-0 bottom-0 right-0 bg-dark h-0.5 origin-left;

        transform: scaleX(var(--scale));
    }
}