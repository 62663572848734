@tailwind base;
@tailwind components;
@tailwind utilities;

@import "base";
@import "import-fonts";
@import "text-size";
@import "list";
@import "loading-animation";
@import "home";
@import "effects";

